<template>
    <div class="active-list" v-if="arr.length" >
        <ul class="article-flex" v-for="(item,index) in arr" :key="index">
            <li class="article-block" @click.prevent="go('/Tasting/wine/commentinfo?id='+item.id)">
                    <div class="articles">
                        <img :src="item.simage" />   
                        <span class="wine-name selected" v-if="item.selected!=0">精选</span>
                        <span class="wine-name">{{item.product_name}}</span>
                        <div class="judge-content Ellipses3">
                                {{item.content}}
                        </div>
                        <div class="time-box">{{item.time}}</div>
                        <div class="icon-box">
                            <span><i class="iconfont iconzan"></i> {{item.zan_num}}</span>
                            <span><i class="iconfont iconxiaoxi1"></i> {{item.comment_num}}</span>
                            <span><i class="iconfont iconxin"></i> {{item.collect_num}}</span>
                        </div>
                    </div> 
            </li>
            <!-- <li class="article-block">
                    <div class="articles">
                        <img src="@assets/images/assemble.jpg" />   
                        <span class="wine-name">十二星座水瓶座智利海藏酒</span>
                        <div class="judge-content Ellipses3">
                                哈哈，大哥结婚喝月光女神，寓意爱情甜甜蜜蜜，口感柔顺，不酸涩，大家都喜欢喝
                        </div>
                        <div class="icon-box">
                            <span><i class="iconfont iconzan"></i> 0</span>
                            <span><i class="iconfont iconxiaoxi1"></i> 0</span>
                            <span><i class="iconfont iconxin"></i> 0</span>
                        </div>
                    </div> 
            </li> -->
        </ul>
        <div class="Loading" v-if="loading">
            <Loading size="24px">加载中...</Loading>
        </div>
        <div class="Loading" v-if="finished">没有更多了~</div>
    </div>
    <div v-else class="active-list">
        <div class="Loading">这里很干净，没有数据</div>
    </div>
</template>
<script>
    import { Icon, Toast, Tab, Tabs ,Loading } from "vant";
    export default {
        name: "UserJudge",
        data: function() {
            return {
                
            };
        },
        props: {
            arr: {
                type: Array,
                default:[]
            },
            loading:{
                type:Boolean,
                default:false
            },
            finished:{
                type:Boolean,
                default:false
            }
        },
        components: {
            Loading
        },
        mounted: function() {
            console.log(this.arr);
        },
        methods: {
                go(url){
                if(url){
                    this.$router.push({ path: url });
                }else{
                    Toast({
                        message: '努力开发中，敬请期待',
                        forbidClick: true,
                    });
                }
                
            },
        }
    }
</script>
<style lang="scss">
        .Loading{
            clear: both;
            text-align: center;
            margin-top: 0.1rem;
            color: #ccc;
        }
        .active-list{
            width: 100%;
            margin-top: 0.3rem;
            .article-flex{
                
            }
         }
        .active-list .article-block{
            float: left;
            width: 50%;
            padding: 0.1rem;
            .articles{
                position:relative;
                box-shadow: 0px 0px 4px 1px rgb(216, 216, 216);
                width: 100%;
                height: 6rem;
                border-radius: 0.1rem;
                //border:1px solid #ccc;
                img{
                    width: 100%;
                    height: 4rem;
                }
                .wine-name{
                    position:absolute;
                    font-size: 0.2rem;
                    padding: 0.08rem 0.1rem;
                    background-color: rgba(0, 0, 0, 0.479);
                    border-radius: 0.1rem;
                    top: 3.3rem;
                    left: 0.1rem;
                    color: #fff;
                }
                .wine-name.selected{
                    font-weight: 600;
                    top: 0.1rem;
                    background-color:rgba(255, 0, 0);
                    left:unset;
                    right: 0.1rem;
                }
                .judge-content{
                    padding: 0.08rem 0.1rem;
                }
                .icon-box{
                        position: absolute;
                        font-size: 0.2rem;
                        bottom: 5px;
                        right: 1px;
                        span{
                            margin-right: 0.1rem;
                            .iconfont{
                                 font-size: 0.2rem;
                            }
                        }  
                }
                .time-box{
                    position: absolute;
                    font-size: 0.2rem;
                    color: rgb(184, 184, 184);
                    bottom: 5px;
                    left: 5px;
                }
            }
        }
</style>