<template>
    <div class="judge">
        <div class="head">
        <div class="info-head">
            <div class="info-count" v-if="!judgeCount">
                <div  class="info-head-count-one" >
                        <div><span style="font-size: 0.6rem;">未发布品鉴</span></div>
                        <div style="font-size: 0.3rem;"><span  @click.prevent="goPage('/Tasting/wine/Release')" style="color:#f00;">立即前往</span>分享您的品酒感受吧.</div>
                </div>
            </div>
            <div class="info-count" v-else>
                <div class="info-head-count-one" >
                        <span class="hh">共发表<span style="color:#f00;font-size: 0.5rem;">{{judgeCount}}</span>篇品鉴</span> 
                        <span class="hh">已有<span style="color:#f00;font-size: 0.5rem;">{{jingCount}}</span>篇获得精选</span>
                </div>
                <div class="info-head-count-two">
                    <!-- <div class="info-font" style="border-left: 10px solid #000">
                    </div> -->
                    <div class="info-font">
                        <div><span class="iconfont iconzan"></span>获赞</div>
                        <div>{{zanCount}}</div>
                    </div>
                    <!-- <div class="info-font">
                        <div><span class="iconfont iconxiaoxi1"></span>评论</div>
                        <div>{{commentCount}}</div>
                    </div> -->
                    <div class="info-font">
                        <div><span class="iconfont iconxin"></span>收藏</div>
                        <div>{{collectCount}}</div>
                    </div>
                </div>
            </div>
        </div>
        <form class="search-haed" action="javascript:void 0" @submit.prevent="query">
            <div class="search">
                <div class="iconfont iconsearch"></div>
                <input  type="search" placeholder="请输入内容" v-model="keyword" />
                <div class="chongzhi" @click="setReset()" v-if="listshow" >重置</div>
            </div>
        </form>
        <Tabs v-model="activeName" title-active-color="#000" v-on:click="Loadmore()">
                    <!-- <tab  :title="'品鉴 '+judgeCount" name="a"></tab> -->
                    <!-- <tab  :title="'相册 '+imageCount" name="b"></tab> -->
                    <!-- <tab  :title="'视频 '+videoCount" name="c"></tab> -->
                    <tab  title="品鉴" name="a"></tab>
                    <!-- <tab  title="收藏" name="b"></tab> -->
                    <tab  title="视频" name="c"></tab>
        </Tabs>
        </div>
        <div class="tabs-content">
            <div v-if="activeName=='a'" >
                <div class="list-box">
                    <div class="tage-screen-box">
                        <div class="screen">
                            <span :class="active=='a'?'active':''" @click.stop="qiehuan('a')">作品列表</span>
                            <span :class="active=='b'?'active':''" @click.stop="qiehuan('b')">我的收藏</span>
                            <!-- <span :class="active=='c'?'active':''" @click="qiehuan('c')">足迹</span> -->
                        </div>
                        <div class="examine-list-bit"  @click.prevent="goPage('/Tasting/user/judge/examine')">审核列表 <span class="iconfont iconxiangyoujiantou"></span></div>
                        <div style="clear: both;"></div>
                    </div>
                    <div v-if="active=='a'">
                            <UserJudge 
                            :arr="judge.list"
                            :loading="judge.loading"
                            :finished="judge.finished"
                            ></UserJudge>
                            <!-- <div class="active-list">
                                <ul class="article-flex">
                                    <li class="article-block">
                                        <div class="articles">
                                            <img src="@assets/images/assemble.jpg" />   
                                            <span class="wine-name">十二星座水瓶座智利海藏酒</span>
                                            <div class="judge-content Ellipses3">
                                                    哈哈，大哥结婚喝月光女神，寓意爱情甜甜蜜蜜，口感柔顺，不酸涩，大家都喜欢喝
                                            </div>
                                            <div class="time-box">2021-04-14</div>
                                            <div class="icon-box">
                                                <span><i class="iconfont iconzan"></i> 0</span>
                                                <span><i class="iconfont iconxiaoxi1"></i> 0</span>
                                                <span><i class="iconfont iconxin"></i> 0</span>
                                            </div>
                                        </div> 
                                    </li> 
                                </ul>
                                <div class="Loading">没有更多了~</div>
                            </div> -->
                    </div>
                    <div v-if="active=='b'">
                            <UserJudge 
                            :arr="collect.list"
                            :loading="collect.loading"
                            :finished="collect.finished"
                            ></UserJudge>
                    </div>
                </div>
            </div>
            <div v-if="activeName=='b'" >
                <div class="list-box" style="color: #ccc;text-align: center;">
                    <div>正在努力开发中...</div>
                    <div>敬请期待</div>
                </div>
            </div>
            <div v-if="activeName=='c'">
                <div class="list-box" style="color: #ccc;text-align: center;">
                    <div>正在努力开发中...</div>
                    <div>敬请期待</div>
                </div>
            </div>
        </div>
        <Judge></Judge>
        <Backtop></Backtop>
    </div>
</template>
<script>
    import { JudgeUserList,JudgeCount } from "@api/tasting";
    import Judge from "../../components/Judge";
    import Backtop from "../../components/Backtop";
    import UserJudge from "../components/UserJudge";
    import { Icon, Toast, Tab, Tabs ,Loading } from "vant";
    export default {
        name: "userjudgelist",
        data: function() {
            return {
                judgeCount:0,
                imageCount:0,
                videoCount:0,
                jingCount:0,
                zanCount:0,
                commentCount:0,
                collectCount:0,
                activeName:'a',
                keyword:'',
                listshow:false,
                active:'a',
                judge:{
                    page:1,
                    limit:4,
                    finished:false,
                    loading:false,
                    list:[]
                },
                collect:{
                    uid:0,
                    page:1,
                    limit:4,
                    finished:false,
                    loading:false,
                    list:[]
                }
            
            };
        },
        components: {
            Icon, Toast, Tab, Tabs ,Loading ,Judge ,Backtop ,UserJudge
        },
        created: function() {
            this.activeName=this.$route.query.active;
        }, 
        mounted: function() {
            this.JudgeCounts();
            this.Loadmore();
            var that=this;
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
                    that.Loadmore();
                }
            });
        },
        methods: {
            goPage(url){
                if(url){
                    this.$router.push({ path: url });
                }else{
                    Toast({
                        message: '努力开发中，敬请期待',
                        forbidClick: true,
                    });
                }
                
            },
            query(){
                this.getKeyword();
            },
            qiehuan(val){
                this.active = val
            },
            Loadmore: function() {
                if(this.active=="a"){
                    if(this.judge.loading)return;
                    if(this.judge.finished)return;
                    this.judgeList(false);
                }else{
                    if(this.collect.loading)return;
                    if(this.collect.finished)return;
                }
            },
            //搜索
            getKeyword(){
                //文章类搜索
                this.listshow = true;
                if(this.active=="a"){
                    this.judge.page=1;
                    this.judge.limit=4;
                    this.judgeList(true);
                }else{
                    this.collect.page=1;
                    this.collect.limit=4;
                }
            },
            //重置
            setReset(){
                this.keyword = '';
                if(this.active=="a"){
                    this.judge.page=1;
                    this.judge.limit=4;
                    this.judge.list=[];
                }else{
                    this.collect.page=1;
                    this.collect.limit=4;
                    this.collect.list=[];
                }
                this.judgeList(false);
                this.listshow = false;
            },
            //统计
            JudgeCounts:function(){
                JudgeCount().then(res => {
                    this.judgeCount =res.data.judge;
                    this.jingCount = res.data.selected;
                    this.zanCount = res.data.zan;
                    this.collect = res.data.collect;
                }).catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            //文章列表
            judgeList:function(type){
                this.judge.loading = true;
                JudgeUserList({
                    page:this.judge.page,
                    limit:this.judge.limit,
                    keyword:this.keyword,
                    status:3,//通过列表 不显示隐藏
                }).then(res => {
                    //this.judgeCount =res.data.count;
                    //this.jingCount = res.data.selectedCount;
                    var listdata = res.data.list;
                    if(type){
                        this.judge.list = listdata;
                        this.judge.loading = false;
                        this.judge.finished = true;
                    }else{
                        listdata.forEach((item)=>{
                        this.judge.list.push(item);
                        })
                        this.judge.loading = false;
                        this.judge.page++;
                        if(listdata.length<4){
                            this.judge.finished = true;
                        }
                    }
                }).catch(err => {
                    this.judge.loading = false;
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
        }
    
}
</script>
<style lang="scss">
    .judge{
        // position: fixed;
        // overflow:hidden;
        // height: 100%;
    }
    //::-webkit-input-placeholder { color:#FFF; }
    //::-moz-placeholder { color:#FFF; } /* firefox 19+ */
    //input:-moz-placeholder { color:#FFF; }
    .judge .head{
        position: fixed;
        width: 100%;
        z-index: 100;
    }
    .judge .tabs-content{
        background-color: #fff;
        margin-top: 0.2rem;
    }
    .judge .info-head{
        height: 1.5rem;
        color: #000;
        font-weight: 600;
        width: 100%;
        .info-count{
                width: 100%;
                height: 100%;
                background-color: #fff;
                //top: 0.3rem;
                //left: 0.8rem;
                padding: 0.1rem 0 0 0.8rem;
                .info-head-count-one{
                    font-size: 0.35rem;
                    margin-bottom:0.1rem;
                    .hh{
                        margin-right:0.2rem;
                    }  
                }
                .info-head-count-two{
                    font-size: 0.2rem;
                    display: flex;
                    .info-font{
                        color: rgb(175, 175, 175);
                        flex-direction:row;
                        justify-content:space-evenly;
                        margin-right: 0.3rem;
                    }
                    .iconfont{
                        font-size: 0.2rem;
                    }
                }
        }
        
    }
    .judge .search-haed{
        margin: 0 auto;
        width: 100%;
        //height: 1rem;
        //background-image: ;
        background-color: #ffff;
        .search{
            position: relative;
            margin: 0 auto;
            width: 80%;padding: 0.2rem 0;
            input{
                width: 100%;
                padding: 0.1rem 0.6rem;
                padding-right:0.8rem ;
                border: 1px solid #fff;
                border-radius: 0.3rem;
                background-color: #fff;
                color: #000;
                box-shadow: 0px 0px 4px 1px rgb(216, 216, 216);
            }
            .iconfont{
                position: absolute;
                top: 0.3rem;
                left:3%;
            }
            .chongzhi{
                position: absolute;
                top: 0.3rem;
                right: 3%;
                z-index: 2;
            }
        }
    }
    .judge .van-tabs{
        border-bottom: 1px solid #eee;
        .van-tabs__wrap{
        }
    }
    .judge .list-box{
        padding: 0.1rem 0.2rem 0rem 0.2rem;
        margin-top: 48%;
        
      .tage-screen-box{
            font-size: 0.28rem;
            .screen{
                float: left;
                span{
                    font-weight: 600;
                    margin-right: 0.3rem;
                    padding: 0.08rem 0.4rem;
                    border-radius: 0.1rem;
                    //border:1px solid #ccc;
                }
                .active{
                    background-color: #eee;
                    //transform: translateX(-90%); transition-duration: 0.3s;
                }
            }
            .examine-list-bit{
                float:right;
                color: #f00;
                .iconfont{
                    font-size: 0.2rem;
                    font-weight: 600;
                }
            }   
        }
    }
    
</style>